<template>
    <div class="wrapper">
        <div class="container-fluid">
            <router-view></router-view>
            <!-- End container-fluid -->
            <Footer />
        </div>
    </div>
</template>
<script>
import Footer from '@/views/layouts/FooterComponent.vue';

export default {
    name: 'Home',
    components: {
        Footer
    }
}
</script>